// pages/404.js
/**
 * Custom 404 page for handling "Not Found" errors.
 *
 * This component is automatically statically optimized by Next.js
 * and renders when a page is not found.
 */
import NextError from "next/error";

// TODO - Make this more branded
function Custom404() {
	return <NextError statusCode={404} />;
}

export default Custom404;
